













































import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import campos_personalizadoModule from "@/store/modules/campos_personalizado-module";
import objetivos_medicionModule from "@/store/modules/objetivos_medicion-module";
import { campos_personalizado } from "@/shared/dtos/campos_personalizado";
import { objetivos_medicion } from "@/shared/dtos/Objetivos/objetivos_medicion";
import { ssmMessageService } from "@/shared/services/message-service";
@Component({
  components: {},
  $_veeValidate: { validator: "new" }
})
export default class SelectObjetivo extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @Prop() id_paciente!: number;
  public obj_med: number = 0.0;
  public select: any = null;
  public created() {
    campos_personalizadoModule.getcampos_personalizados();
  }

  public get campos_personalizados() {
    return campos_personalizadoModule.campos_personalizados;
  }

  @Watch("syncedDialog")
  public refresh() {
    this.obj_med = 0;
    this.select = null;
    this.$validator.reset();
  }
  public save() {
    this.$validator.validateAll().then(allOk => {
      if (allOk) {
        let campo: campos_personalizado = this.select;
        let objetivo: objetivos_medicion = new objetivos_medicion();
        objetivo.fecha_alta_obj = new Date();
        objetivo.id_paciente = this.id_paciente;
        objetivo.id_campos_personalizados = campo.id;
        objetivo.conseguido = false;
        objetivo.obj_medicion = this.obj_med.toString();
        objetivos_medicionModule
          .guardarobjetivos_medicion(objetivo)
          .then(() => {
            objetivos_medicionModule
              .getobjetivos_mediciones_paciente(this.id_paciente)
              .finally(() => {
                this.syncedDialog = false;
              });
          });
      } else {
        ssmMessageService.toastinfo("Complete los campos");
      }
    });
  }
}
